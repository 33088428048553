// import Cardpic1 from "../src/Images/card1pic.jpg";
import Cardpic2 from "../src/Images/card2pic.jpg";
import Cardpic3 from "../src/Images/card3pic.jpg";
import Headset3 from "../src/Images/headset3.jpg";
import Headset4 from "../src/Images/cd4.jpg";
import { MdLocalShipping, MdLocationOn } from "react-icons/md";
import { FaLock } from "react-icons/fa";
import { FaArrowRotateRight } from "react-icons/fa6";



export const timeDuration = [
    {number:"30" ,time:"Days"},
    {number:"23" ,time:"Hours"},
    {number:"03",time:"minutes"},
    {number:"43",time:"seconds"},


]

export const cardsData = [
    
           {
            id:1, 
            image:Cardpic2,
            discount:"66%",
            heading:"Nexatone",
            originalPrice:"$350",
            rating:"3",
            starColor:"#f90",
            count: 1,
            offer:"240"},

            {
            id:2, 
            image:Cardpic3,
            discount:"76%",
            heading:"Echopulse",
            originalPrice:"$450",
            rating:"4",
            count: 2,
            offer:"420"},

            {
            id:3,
            image:Headset3,
            discount:"46%",
            heading:"Sonowave",
            originalPrice:"$550",
            rating:"3",
            count: 3,
            offer:"510"},

            {
            id:4, 
            image:Headset4,
            discount:"25%",
            heading:"Audiosculpt",
            originalPrice:"$750",
            rating:"4",
            count: 4,
            offer:"500"},
]

export const benefits = [
    {
        icon:<MdLocalShipping  /> ,
        heading:"Free shipping",
        content:"Delight in seamless free shipping,enhancing your shopping experience.Navigate our diverse collection,where cost savings meet product joy."
    },
    {
        icon:<FaLock /> ,
        heading:"secure payments",
        content:"Shop with confidence using our secure payment methods.Your transaction are protected.  providing peace of ming for a shopping."
    },
    {
        icon:<FaLock /> ,
        heading:"secure payments",
        content:"Shop with confidence using our secure payment methods.Your transaction are protected.  providing peace of ming for a shopping."
    }

]

export const clientReview = [
    {
        personName:"James Oliver",
        description:"I couldn't be happier with my black friday shop experience.The deals were incredible,and the qulity of the products i purchased exceeded my expections."
    },
    {
    personName:"Daniel Taylor",
    description:"Black Friday was amazing .The deals were unbeatable, and I saved so much on my holiday shopping."
    }
]

export const chooseUsData = [
    {
        icon:<MdLocalShipping  />,
        heading:"Free shipping",
        content:"Delight in seamless free shipping,enhancing your shopping experience.Navigate our diverse collection,where cost savings meet product joy."
    },
    {
        icon:<FaLock  />,
        heading:"secure payments",
        content:"Shop with confidence using our secure payment methods.Your transaction are protected.  providing peace of ming for a shopping."
    },
    {
        icon:<MdLocationOn  />,
        heading:"order tracking",
        content:"Track your order effortertlessly with our streamlined sstem.Stay informed andin control as your purchase makes its way to your doorstep."
    },
    {
        icon:<FaArrowRotateRight  />,
        heading:"Easy Returns",
        content:"Celebrate worry-free sopping with our hassle-free returns-we here to make your shopping experience smooth  as possible."
    }
]