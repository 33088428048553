import React from 'react'

const Cards2 = ({heading,content,icon}) => {
  return (
   <div className='md:w-52 w-[156px]'>
    <span className="text-[20px] text-white" >{icon}</span>
    <h3 className="md:text-base text-sm text-white font-semibold uppercase">
       {heading}
    </h3>
    <p className="font-light py-5 md:text-sm text-[10px] text-white" >{content}</p>
   
    </div>
  )
}

export default Cards2
