import React from "react";
import { motion } from "framer-motion"

const Button = ({ type, btnName, style,onClick }) => {
  return (
    <div>
      <motion.button
        whileTap={{ scale: 0.9 }}
        type={type}
        className={`text-center px-4 py-2 text-sm rounded-md font-normal ${style}`}
        onClick={onClick}
      >
        {btnName}
      </motion.button>
    </div>
  );
};

export default Button;
