
import './App.css';
import AddCart from './components/AddCart';
import MainPage from './components/MainPage';
import { BrowserRouter as Router,Route,Switch} from "react-router-dom";
// import Navbar from './components/Navbar';

function App() {
  return (
    <div className="App">
      <Router>
      {/* <Navbar /> */}
      <Route path="/" component={MainPage} exact/>     
       <Route path="/addcart" component={AddCart}/> 

      {/* <MainPage/> */}
      </Router>
    </div>
  );
}

export default App;
