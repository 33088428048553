import React from 'react'
import "../css/startrating.css";

const StarRating = ({ rating, onRatingChange, disabled }) => {
  const stars = [1, 2, 3, 4, 5];

  // const getStarClass = (star) => {
  //   return rating >= star ? 'filled-star' : '';
  // };

  return (
    <div className="star-rating">
      {stars.map((star, index) => (
        <React.Fragment key={index}>
          <input
            type="radio"
            id={`${star}-stars`}
            name="rating"
            value={star}
            checked={rating === star}
            onChange={() => onRatingChange(star)}
            disabled={disabled}
          />
          <label
            htmlFor={`${star}-stars`}
            style={{ color: rating >= star ? '#f90' : '#ccc' }} 
          >
            &#9733;
          </label>
        </React.Fragment>
      ))}
    </div>
  );
};

export default StarRating;

