import { createSlice } from "@reduxjs/toolkit";
import Alert from "../../components/Alert";

const initialState = {
  card: [],
  // count: 1,
  totalOffer: 0,
  
};

export const cardSlice = createSlice({
  name: "card",
  initialState,
  reducers: {
   
    
    addCart: (state, action) => {
      const existingProduct = state.card.find(item => item.id === action.payload.id)
      if(existingProduct){
        existingProduct.count +=1;
        existingProduct.totalOffer += existingProduct.offer;
        state.totalOffer += existingProduct.offer;

      }else{
        const newCard = { ...action.payload, offer: parseInt(action.payload.offer), count: 1, totalOffer: parseInt(action.payload.offer) };
       state.card.push(newCard);
       state.totalOffer += newCard.offer
      }
     
    },
    addCount: (state, action) => {
      const product = state.card.find(item => item.id === action.payload);
      if (product) {
        product.count += 1;
        product.totalOffer += product.offer;
        state.totalOffer += product.offer;
      }
    },
    decreaseCount: (state, action) => {
      const product = state.card.find(item => item.id === action.payload);
      if (product) {
        product.count -= 1;
        product.totalOffer -= product.offer;
        state.totalOffer -= product.offer;
        
        if (product.count < 1) {
          const index = state.card.findIndex(card => card.id === action.payload);
          if (index !== -1) {
            state.card.splice(index, 1);
            Alert({title:"Item Removed from Cart",icon:"Success"})
          }
        }
      }
    },
    removeFromCart: (state, action) => {
      const index = state.card.findIndex(card => card.id === action.payload.id);
      if (index !== -1) {
        const product = state.card[index];
        state.totalOffer -= product.totalOffer;  
        state.card.splice(index, 1); 
      }
    },
}

});

export default cardSlice.reducer;
export const { addCart, removeFromCart,addCount ,decreaseCount} = cardSlice.actions;
