import React from "react";
import StartRating from "./StartRating";

const CustmerReview = ({ personName, description }) => {
  return (
    <div>
    <div className="text-start">
      <StartRating rating={5} />
      <p className="text-white md:text-base text-[8px] ">{description}</p>
      <h3 className="text-white mt-2 md:text-base text-[9px]">{personName}</h3>
    </div>
    </div>
  );
};

export default CustmerReview;
