import React from "react";
import StartRating from "./StartRating";
import Button from "./Button";
import {useDispatch} from "react-redux"
import { addCart } from "../Redux/features/cardSlice";
import Alert from "./Alert";

const Cards = ({
  id,
  image,
  discount,
  heading,
  onRatingChange,
  rating,
  originalPrice,
  offer,
  index,
  type,
  btnName,
  style,
  onClick
}) => {
  const dispatch = useDispatch();

  

  const handleAddedItem = ()=>{
    dispatch(addCart({id,heading,image,offer}))
    Alert({title:"Product Added to Cart",icon:"success"})
  }
  return (
    <div className="cards relative  duration-300">
      <img src={image} className="rounded-sm " alt="cardimage" />
      <h6 className="absolute top-3 left-2 px-2 py-1  rounded-[45px] bg-blue-300 text-white text-[12px]">
        {discount}
      </h6>
      <div className="description text-start">
        <h2 className="text-white uppercase font-bold">{heading}</h2>
        <StartRating rating={rating} onRatingChange={newRating => onRatingChange(index, newRating)}  starcolor="cursor-pointer text-[#FF4A56]" />
        <span className=" inline-flex font-medium gap-2">
          <h3 className="text-slate-400 line-through">{originalPrice}</h3>
          <h3 className="text-white">{offer}</h3>
        </span>
        <Button type={type} btnName={btnName} style={style}  onClick={handleAddedItem} />
      </div>
    </div>
  );
};

export default Cards;
