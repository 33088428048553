import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import "../css/mainpage.css";
import Button from "./Button";
import NumberCards from "./NumberCards";
import Headset from "../Images/heaset.png";
import Headset2 from "../Images/headset2.jpg";
import { benefits, cardsData, chooseUsData, clientReview, timeDuration } from "../data";
import Cardpic1 from "../Images/card1pic.jpg";
import StartRating from "./StartRating";
import Cards from "./Cards";
import { MdLocalShipping, MdLocationOn } from "react-icons/md";
import { FaLock } from "react-icons/fa";
import { FaArrowRotateRight } from "react-icons/fa6";
import Cards2 from "./Cards2";
import CustmerReview from "./CustmerReview";
// import {motion} from "framer-motion";
import {motion} from "framer-motion"

const MainPage = () => {
  const [rating, setRating] = useState(0);

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };
  const initialTime = {
    days: 15,
    hours: 18,
    minutes: 3,
    seconds: 43,
  };

  const [timeDuration, setTimeDuration] = useState(initialTime);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeDuration((prevTime) => {
        let { days, hours, minutes, seconds } = prevTime;

        seconds -= 1;
        if (seconds < 0) {
          seconds = 59;
          minutes -= 1;
        }
        if (minutes < 0) {
          minutes = 59;
          hours -= 1;
        }
        if (hours < 0) {
          hours = 23;
          days -= 1;
        }
        if (days < 0) {
          clearInterval(timer);
        }

        return { days, hours, minutes, seconds };
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formattedTimeDuration = [
    { number: timeDuration.days, time: 'Days' },
    { number: timeDuration.hours, time: 'Hours' },
    { number: timeDuration.minutes, time: 'Minutes' },
    { number: timeDuration.seconds, time: 'Seconds' },
  ];
  return (
    <div className="bg-black w-full">
      {/* Nav section */}
      <Navbar sec1="Home" sec2="Shop" sec3="About" sec4="Contact" />

      <div className="lg:pl-18 mt-16  sectiononehead  ">
        <h3 className="text-[#FF4A56] blinkword md:text-lg text-sm italic ">
          Hurry,Snap Deals is almost here!
        </h3>
        <br />
        
        <motion.span className="md:text-5xl text-[14px] text-white block" initial={{y:"2rem",opacity:0}}
            animate={{y:0,opacity:1}}
            transition={{
                duration:2,
                 type:"spring"
            }}>
          Time Left Until our{" "}
          <span className="text-[#FF4A56]">biggest Sales</span>
        </motion.span>
        <br />
        <motion.span className="md:text-5xl text-[14px] text-[#FF4A56] block "
        initial={{y:"2rem",opacity:0}}
        animate={{y:0,opacity:1}}
        transition={{
            duration:2,
             type:"spring"
        }}
        >of the year begins</motion.span>
        <Button
          type="button"
          btnName="Set Reminder"
          style="bg-[#FF4A56] text-white mt-4 uppercase "
        />
      </div>

      <div className="md:py-4 md:px-20 md:pl-12  ">
        <span className="flex justify-center flex-row gap-5 intro-x ">
          {formattedTimeDuration.map((data, index) => (
            <motion.div key={index}  initial={{x:"2rem",opacity:0}}
            animate={{x:0,opacity:1}}
            transition={{
                duration:2,
                 type:"spring"
            }}>
              <NumberCards number={data.number} time={data.time} />
            </motion.div>
          ))}
        </span>
      </div>

      <div className="mt-14 lg:px-24 md:px-16 px-3 sectionthree">
        <div className=" head bg-[#191919] md:py-10 md:px-2 p-4 md:p-[30px] ">
          {/* <span className="flex  flex-row lg:pl-40 border ">
        <div class="flex-1 w-24 flex-col py-10">
       <h3 className="text-[#FF4A56] text-xl whitespace-nowrap italic">
          Up To 50% off 
        </h3><br/>
        <span className="text-5xl font-semibold whitespace-nowrap text-white uppercase">
          Grab your <br/>Favorites before<br/>they're gone
        </span>
        <p className="text-white mt-3 whitespace-nowrap">You can trust us to bring you the latest technology at<br/>unbeatable prices.Don't miss this limited time <br/>opportunity to upgrade your audio game.Grab your<br/>perfect pair now!</p>
        <Button
          type="button"
          btnName="Show Now"
          style="bg-[#FF4A56] text-white font-normal mt-4 uppercase"
        />
       </div>
       <div class="flex-0 w-72 justify-end  py-10" >
            <img src={Headset} alt="headset" />
       </div>
       </span> */}
          <div className="content text-start md:p-[20px] lg:p-0">
            <h3 className="text-[#FF4A56] md:text-xl lg:text-xl text-base  whitespace-nowrap italic">
              Up To 50% off
            </h3>
            <span className="lg:text-5xl md:text-5xl text-base font-semibold text-white uppercase">
              Grab your <br />
              Favorites before
              <br />
              they're gone
            </span>
            <p className=" text-sm text-white mt-3">
              You can trust us to bring you the latest technology at
              <br />
              unbeatable prices.Don't miss this limited time <br />
              opportunity to upgrade your audio game.Grab your
              <br />
              perfect pair now!
            </p>
            <Button
              type="button"
              btnName="Show Now"
              style="bg-[#FF4A56] text-white font-normal mt-4 uppercase"
            />
          </div>
          <img src={Headset} alt="headset" className="md:w-64 w-32" />
        </div>
      </div>

      <div className=" mt-14 flex md:px-20 px-5 flex-row justify-evenly">
        <div className="text-start">
          <h3 className="text-[#FF4A56] md:text-xl text-sm whitespace-nowrap italic">
            Snap Deals Exclusive
          </h3>
          <span className="md:text-5xl text-base font-semibold text-white uppercase">
            Save Big:
            <span className=" text-[#FF4A56]">
              Up to 75% <br />
              <span className="text-white uppercase">off on headphones</span>
            </span>
          </span>
          <p className=" text-white mt-3">
          Rest assured that we offer cutting-edge technology at prices you won't find elsewhere. <br/>
          Take advantage of this exclusive chance to enhance your audio experience <br/> while stocks last.
           
          </p>
          <Button
            type="button"
            btnName="Show Now"
            style="bg-[#FF4A56] text-white font-normal mt-4 uppercase"
          />
        </div>
        <div className="">
          <img
            src={Headset2}
            alt="exclusive headset"
            className="md:h-[23rem] md:w-[27rem] h-[17rem] w-[24rem] rounded-sm"
          />
        </div>
      </div>

      {/* {cards section} */}

      <div className="mt-10">
        <span className="flex justify-center flex-col ">
          <h3 className="italic text-[#FF4A56] text-[18px] font-medium ">
            Unbelievable Discounts Await You
          </h3>
          <h2 className="text-white md:text-[30px] text-[20px] font-bold uppercase">
          Snap Deals Madness is here!
          </h2>
        </span>
        <div className="grid md:grid-cols-4 grid-cols-1 gap-[30px] mt-4 p-14 md:p-[0.5rem] lg:px-20">
          {cardsData.map((data, index) => (
            <div key={index} className="px-10 md:px-5">
              <Cards
                id={data.id}
                image={data.image}
                discount={data.discount}
                rating={data.rating}
                // starColor={data.starColor}
                onRatingChange={handleRatingChange}
                heading={data.heading}
                originalPrice={data.originalPrice}
                offer={data.offer}
                btnName="Add Card"
                style="bg-[#FF4A56] text-white font-normal mt-4 uppercase "
              />
            </div>
          ))}
        </div>

        <div className="md:px-24 px-10 mt-4 md:mt-8">
          <div className="md:p-20 p-[15px] bg-[#594912] ">
            <div className="content text-start ">
              <h3 className="text-[#FF4A56] md:text-xl text-sm text-center whitespace-nowrap italic">
                Limited-Time offer
              </h3>
              <span className="md:text-5xl text-base font-semibold text-white uppercase">
                Save Big:up to <span className="text-[#FF4A56]">60% off</span>{" "}
                on all Headphones
              </span>
              <p className=" text-white text-center font-medium mt-3 uppercase">
                Act Fast Before They're Gone
              </p>
              <div className="text-center">
                <Button
                  type="button"
                  btnName="Show Now"
                  style="bg-[#FF4A56] text-center text-white font-normal mt-4 uppercase"
                />
              </div>
            </div>
          </div>
        </div>
        {/* why choose */}
       
          <div className=" flex justify-around mt-4 text-start md:px-10 md:py-10 px-7 py-4">
            <div >
              <h3 className="text-[#FF4A56] md:text-xl text-sm  ffont-semibold italic">
                why choose Snap Deals Shop?
              </h3>
              <h3 className="lg:text-[50px] md:leading-none md:text-[35px] text-base text-white font-semibold uppercase">
                The joy of <br />
                shopping at its <br />
                best
              </h3>
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
            {chooseUsData.map((data,index)=>(
              <div key={index}>
               <Cards2
               icon={data.icon}
               heading={data.heading}
               content={data.content}
             />
             </div>
            ))}
              
     
            </div>
          </div>

          {/* {customer review} */}
          <div className="md:px-24 px-4 ">
          <div className="lg:p-20 p-5  reviewbackground">
          <div className="overlay"></div> 
             <div className="content text-start ">
            <h3 className="text-[#FF4A56] md:text-xl text-sm ffont-semibold italic">
               what our clients say
              </h3>
              <span className="md:text-4xl text-sm font-semibold text-white uppercase">
                Customers testimonials
              </span>
            </div> 
              <div className="grid content md:grid-cols-2 grid-cols-1 md:gap-10 md:pt-10">
              {clientReview.map((data,item)=>(
              <div key={item}>
                 <CustmerReview 

              personName={data.personName}
              description={data.description}
              />
                </div>
              ))}
             
            </div>  
          </div>
        </div>

        {/* footer content */}
        <div className="lg:pl-18 mt-16  sectiononehead ">
        <h3 className="text-[#FF4A56] font-medium md:text-lg text-sm italic">
        Snap Deals Sale
        </h3>
        <br />
        <span className="md:text-5xl text-base text-white uppercase">
          Save big UP To{" "}
          <span className="text-[#FF4A56]">60% off on</span>
        </span>
        <br />
        <span className="md:text-5xl text-base  text-white uppercase">All Products </span>
        <p className="text-white text-[10px] md:text-base mt-5">Don't miss this exclusive  oppertunity on journey of unbeatable savings and <br/>cutting-edge technology.It's time to secure best deals and upgrade experience.</p>
        <Button
          type="button"
          btnName="Shop Deals"
          style="bg-[#FF4A56] text-white mt-4 uppercase mb-20"
        />
      </div>

      {/* footer */}
      <div className="border-y border-slate-400 px-10 py-10">
        <div className="flex justify-end gap-5 text-white">
          <span className="text-[#FF4A56]">Home</span>
          <span>Shop</span>
          <span>About</span>
          <span>Contact</span>
        </div>
      </div>
        </div>
      
    </div>
  );
};

export default MainPage;
