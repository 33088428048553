import React from 'react'

const NumberCards = ({number,time}) => {
  return (
    <div className='border border-[#252525] lg:px-12 px-4 md:px-8 py-2 mt-3 '>
      <span className=''>
      <span className='text-white md:text-[80px] lg:text-[80px] text-[35px] flex justify-center items-center font-normal'>{number}</span>
      <h4 className='text-white md:text-[14px] text-[10px]  font-normal mb-5'>{time}</h4>
      </span>
    </div>
  )
}

export default NumberCards
